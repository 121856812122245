import React from 'react'
import { Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { ExtensionFormBase } from 'methone/components/ExtensionFormBase'
import { HookFormInput, HookFormPassword } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { EXTENSION_STATUS } from 'methone/shared/constants'
import { ExtensionResponseDTO } from 'methone/shared/dto/ExtensionResponseDTO'

import { AmazonSESEmailProviderStyledContainer } from './styled'

const defaultValues: ExtensionResponseDTO<AmazonSESConfig> = {
  id: '',
  name: '',
  type: '',
  enabled: true,
  status: EXTENSION_STATUS.STOPPED,
  jsonConfig: {
    accessKeyId: '',
    emailAddress: '',
    emailName: '',
    region: '',
    secretAccessKey: ''
  },
  createdAt: undefined,
  createdBy: '',
  updatedAt: undefined,
  updatedBy: '',
  deletedAt: undefined,
  deletedBy: ''
}

export const AmazonSESEmailProviderForm: React.FC = () => {
  const { control, ...rest } = useForm<ExtensionResponseDTO<AmazonSESConfig>>({ defaultValues, mode: 'all' })

  return (
    <ExtensionFormBase control={control} {...rest}>
      <Card>
        <Card.Body>
          <AmazonSESEmailProviderStyledContainer>
            <HookFormInput control={control} name="jsonConfig.emailName" label={i18n('Email name')} />
            <HookFormInput control={control} name="jsonConfig.emailAddress" label={i18n('Email address')} />
            <HookFormPassword control={control} name="jsonConfig.accessKeyId" label={i18n('Access key id')} />
            <HookFormPassword control={control} name="jsonConfig.secretAccessKey" label={i18n('Secret access key')} />
            <HookFormInput control={control} name="jsonConfig.region" label={i18n('Region')} />
          </AmazonSESEmailProviderStyledContainer>
        </Card.Body>
      </Card>
    </ExtensionFormBase>
  )
}
