import styled from 'styled-components'

export const AmazonSESEmailProviderStyledContainer = styled.div`
  display: grid;
  grid-template-areas: 'AK SK RG BK';
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: var(--spacing-2);

  .form-input-container[class*='form-input-container--jsonConfig.accessKeyId'] {
    grid-area: AK;
  }

  .form-input-container[class*='form-input-container--jsonConfig.secretAccessKey'] {
    grid-area: SK;
  }

  .form-input-container[class*='form-input-container--jsonConfig.region'] {
    grid-area: RG;
  }

  .form-input-container[class*='form-input-container--jsonConfig.bucket'] {
    grid-area: BK;
  }
`
