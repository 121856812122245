import { extensionRendererService } from 'methone/services/extensionRendererService'
import { i18n } from 'methone/services/i18n'

import { AmazonS3StorageProviderForm } from './AmazonS3StorageProviderForm'
import { AmazonSESEmailProviderForm } from './AmazonSESEmailProviderForm'
import { enUS, ptBR } from './i18n'

i18n.addItems('pt-BR', ptBR.values)
i18n.addItems('en-US', enUS.values)

extensionRendererService.registerRenderer({
  type: 'AmazonSESEmailProvider',
  component: AmazonSESEmailProviderForm
})

extensionRendererService.registerRenderer({
  type: 'AmazonS3StorageProvider',
  component: AmazonS3StorageProviderForm
})
